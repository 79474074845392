
export default {
  name: "BannerCarousel",
  props: {
    placement: {
      type: String,
      default: "Main"
    }
  },
  methods: {
    hasLink(banner) {
      return banner.link.length !== 0;
    },
    getAltForBanner(banner) {
      if(banner.title && banner.description) {
        return banner.title + ' ' + banner.description;
      }
      if(banner.title) {
        return banner.title;
      }

      return null;
    },
    navigateToBannerLink(banner) {
      if (!this.hasLink(banner)) {
        return;
      }
      if(banner.link?.url) {
        this.emitGTMEvent(banner);
        window.open(banner.link.url, '_blank').focus();
      } else {
        this.emitGTMEvent(banner);
        this.$router.push(
          this.localePath({
            name: this.$pageDataHelper.getRouteNameByPageIdentifier(banner.link),
            params: banner.link.params
          })
        );
      }
    },
    emitGTMEvent(banner) {
      if(process.client && window && window.dataLayer) {
        window.dataLayer.push({
          'event': 'click_banner',
          'banner_id': banner.id
        })
      }
    }
  },
  computed: {
    getBanners() {
      return this
        .$store.getters['pageData/getBannersForPage'](this.$pageDataHelper.getPageIdentifierByRoute(this.$route))
        .filter(b => b.type === "image" && b.placement === this.placement);
    },
    getResponsiveConfig() {
      return [
        {
          minWidth: 0,
          peekLeft: 0,
          peekRight: 0,
          slidesPerPage: 1
        },
        {
          // md - 768
          minWidth: 768,
          peekLeft: 40,
          peekRight: 40,
          slidesPerPage: 1
        },
        {
          // lg - 1024
          minWidth: 1024,
          peekLeft: 100,
          peekRight: 100,
          slidesPerPage: 1
        },
        {
          // 2xl - 1536
          minWidth: 1536,
          peekRight: 230,
          peekLeft: 230,
          slidesPerPage: 1,
        },
        {
          // Between 2xl and 3xl
          minWidth: 1700,
          peekRight: 280,
          peekLeft: 280,
          slidesPerPage: 1,
        },
        {
          // 3xl - 1900
          minWidth: 1900,
          peekLeft: 220,
          peekRight: 220,
          slidesPerPage: 1
        },
        {
          // Between 3xl and 4xl
          minWidth: 2100,
          peekLeft: 320,
          peekRight: 320,
          slidesPerPage: 1
        },
        {
          // 4xl - 2320
          minWidth: 2320,
          peekLeft: 400,
          peekRight: 400,
          slidesPerPage: 1,
        },
      ];
    }
  },
}
